const aboutItems = [
  {
    name: 'À propos',
    href: '/[[...slug]]',
    as: '/a-propos',
    locale: 'fr',
  },
  {
    name: 'Équipe',
    href: '/team',
    as: '/team',
    locale: 'fr',
  },
  {
    name: 'Donateurs',
    href: '/donors',
    as: '/donors',
    locale: 'fr',
  },
  {
    name: 'Contact',
    href: '/[[...slug]]',
    as: '/contact-fr',
    locale: 'fr',
  },
]

const ourWorkItems = [
  {
    name: 'Engagement au niveau de l’ONU',
    href: '/[[...slug]]',
    as: '/notre-travail/engagement-nations-unies',
    locale: 'fr',
  },
  {
    name: 'Plaidoyer national et régional',
    href: '/[[...slug]]',
    as: '/notre-travail/plaidoyer-national-regional',
    locale: 'fr',
  },
  {
    name: "Centre d'apprentissage",
    href: '/[[...slug]]',
    as: '/notre-travail/centre-apprentissage',
    locale: 'fr',
  },
  {
    name: 'Plan stratégique',
    href: '/[[...slug]]',
    as: '/notre-travail/plan-strategique',
    locale: 'fr',
  },
]

const themesItems = [
  {
    name: 'Droits humains',
    href: '/[[...slug]]',
    as: '/themes-fr/droits-humains',
    locale: 'fr',
  },
  {
    name: 'Décriminalisation',
    href: '/[[...slug]]',
    as: '/themes-fr/decriminalisation',
    locale: 'fr',
  },
  {
    name: 'Genre',
    href: '/[[...slug]]',
    as: '/themes-fr/genre',
    locale: 'fr',
  },
  {
    name: 'Santé',
    href: '/[[...slug]]',
    as: '/themes-fr/sante',
    locale: 'fr',
  },
  {
    name: 'Développement',
    href: '/[[...slug]]',
    as: '/themes-fr/developpement',
    locale: 'fr',
  },
]

const newsroomItems = [
  {
    name: 'Nouvelles',
    href: '/news',
    as: '/news',
    locale: 'fr',
  },
  {
    name: 'Blog',
    href: '/blog',
    as: '/blog',
    locale: 'fr',
  },
  {
    name: "L'IDPC dans les médias",
    href: '/media',
    as: '/media',
    locale: 'fr',
  },
  {
    name: 'Communiqués de presse',
    href: '/press',
    as: '/press',
    locale: 'fr',
  },
  {
    name: "Bulletin d'information",
    href: '/[[...slug]]',
    as: '/medias/bulletins',
    locale: 'fr',
  },
  {
    name: "Offres d'emploi",
    href: '/jobs',
    as: '/jobs',
    locale: 'fr',
  },
]

const items = [
  {
    name: 'Accueil',
    href: '/[[...slug]]',
    as: '/',
    locale: 'fr',
  },
  {
    name: 'À propos',
    submenu: aboutItems,
  },
  {
    name: 'Membres',
    href: '/members',
    as: '/members',
    locale: 'fr',
  },
  {
    name: 'Notre travail',
    submenu: [
      {
        items: ourWorkItems,
      },
      {
        name: 'Thèmes',
        items: themesItems,
      },
    ],
  },
  {
    name: 'Publications',
    href: '/publications',
    as: '/publications',
    locale: 'fr',
  },
  {
    name: 'Événements',
    href: '/events',
    as: '/events',
    locale: 'fr',
  },
  {
    name: 'Actualités',
    submenu: newsroomItems,
  },
]

export default items
