const aboutItems = [
  {
    name: 'Sobre',
    href: '/[[...slug]]',
    as: '/nosotros',
    locale: 'es',
  },
  {
    name: 'Equipo',
    href: '/team',
    as: '/team',
    locale: 'es',
  },
  {
    name: 'Donantes',
    href: '/donors',
    as: '/donors',
    locale: 'es',
  },
  {
    name: 'Contacto',
    href: '/[[...slug]]',
    as: '/contacto',
    locale: 'es',
  },
]

const ourWorkItems = [
  {
    name: 'Incidencia a nivel del sistema ONU',
    href: '/[[...slug]]',
    as: '/nuestro-trabajo/incidencia-a-nivel-del-sistema-de-la-onu',
    locale: 'es',
  },
  {
    name: 'Incidencia a nivel nacional y regional',
    href: '/[[...slug]]',
    as: '/nuestro-trabajo/incidencia-a-nivel-nacional-y-regional',
    locale: 'es',
  },
  {
    name: 'Espacio de aprendizaje',
    href: '/[[...slug]]',
    as: '/nuestro-trabajo/espacio-de-aprendizaje',
    locale: 'es',
  },
  {
    name: 'Plan estratégico',
    href: '/[[...slug]]',
    as: '/nuestro-trabajo/plan-estratégico',
    locale: 'es',
  },
]

const themesItems = [
  {
    name: 'Derechos humanos',
    href: '/[[...slug]]',
    as: '/temas/derechos-humanos',
    locale: 'es',
  },
  {
    name: 'Descriminalización',
    href: '/[[...slug]]',
    as: '/temas/descriminalización',
    locale: 'es',
  },
  {
    name: 'Género',
    href: '/[[...slug]]',
    as: '/temas/genero',
    locale: 'es',
  },
  {
    name: 'Salud',
    href: '/[[...slug]]',
    as: '/temas/salud',
    locale: 'es',
  },
  {
    name: 'Desarollo',
    href: '/[[...slug]]',
    as: '/temas/desarrollo',
    locale: 'es',
  },
]

const newsroomItems = [
  {
    name: 'Noticias',
    href: '/news',
    as: '/news',
    locale: 'es',
  },
  {
    name: 'Blog',
    href: '/blog',
    as: '/blog',
    locale: 'es',
  },
  {
    name: 'IDPC en los medios',
    href: '/media',
    as: '/media',
    locale: 'es',
  },
  {
    name: 'Notas de prensa',
    href: '/press',
    as: '/press',
    locale: 'es',
  },
  {
    name: 'Boletines',
    href: '/[[...slug]]',
    as: '/actualidad/boletines-informativos',
    locale: 'es',
  },
  {
    name: 'Vacantes de trabajo',
    href: '/jobs',
    as: '/jobs',
    locale: 'es',
  },
]

const items = [
  {
    name: 'Inicio',
    href: '/[[...slug]]',
    as: '/',
    locale: 'es',
  },
  {
    name: 'Nosotros',
    submenu: aboutItems,
  },
  {
    name: 'Membresía',
    href: '/members',
    as: '/members',
    locale: 'es',
  },
  {
    name: 'Nuestro trabajo',
    submenu: [
      {
        items: ourWorkItems,
      },
      {
        name: 'Temas',
        items: themesItems,
      },
    ],
  },
  {
    name: 'Publicaciones',
    href: '/publications',
    as: '/publications',
    locale: 'es',
  },
  {
    name: 'Eventos',
    href: '/events',
    as: '/events',
    locale: 'es',
  },
  {
    name: 'Actualidad',
    submenu: newsroomItems,
  },
]

export default items
