import { useState } from 'react'
const useMultiSelect = (initialValue) => {
  const [selectedOptions, setSelectedOptions] = useState([])

  return {
    selectedOptions,
    setSelectedOptions,
    reset: () => {
      setSelectedOptions([])
      setQuery('')
    },
  }
}

export default useMultiSelect
