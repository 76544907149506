import { createContext, useContext, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import useInput from 'hooks/inputHook'
import useMultiSelect from 'hooks/multiSelectHook'
import { postTypes } from '@lib/utils'

export const SearchContext = createContext()

const SearchProvider = ({ children }) => {
  const router = useRouter()

  const {
    value: textQuery,
    setValue: setTextQuery,
    bind: bindTextQuery,
  } = useInput('')
  const {
    selectedOptions: typesSelectedOptions,
    setSelectedOptions: typesSetSelectedOptions,
  } = useMultiSelect()
  const {
    selectedOptions: orgsSelectedOptions,
    setSelectedOptions: orgsSetSelectedOptions,
  } = useMultiSelect()
  const {
    selectedOptions: subjectsSelectedOptions,
    setSelectedOptions: subjectsSetSelectedOptions,
  } = useMultiSelect()
  const {
    selectedOptions: regionsSelectedOptions,
    setSelectedOptions: regionsSetSelectedOptions,
  } = useMultiSelect()
  const {
    value: publishedAtStart,
    setValue: setPublishedAtStart,
    bind: bindPublishedAtStart,
  } = useInput('')
  const {
    value: publishedAtEnd,
    setValue: setPublishedAtEnd,
    bind: bindPublishedAtEnd,
  } = useInput('')
  const {
    value: eventDate,
    setValue: setEventDate,
    bind: bindEventDate,
  } = useInput('')

  const [subjects, setSubjects] = useState([])
  const [regions, setRegions] = useState([])

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [postIndexType, setPostIndexType] = useState(false)

  useEffect(() => {
    if (postIndexType) {
      typesSetSelectedOptions([[postIndexType, postTypes[postIndexType]]])
    }
  }, [postIndexType, typesSetSelectedOptions])

  const search = () => {
    router.push({
      pathname: '/search',
      query: {
        q: textQuery !== '' ? textQuery : null,
        org: orgsSelectedOptions.map((org) => org._id),
        subject: subjectsSelectedOptions.map((subject) => subject._id),
        region: regionsSelectedOptions.map((region) => region._id),
        type: typesSelectedOptions.map((type) => type[0]),
        start: publishedAtStart !== '' ? publishedAtStart : null,
        end: publishedAtEnd !== '' ? publishedAtEnd : null,
        edate: eventDate !== '' ? eventDate : null,
      },
    })
    setIsDialogOpen(false)
  }

  return (
    <SearchContext.Provider
      value={{
        filters: {
          text: {
            bindTextQuery,
            textQuery,
            setTextQuery,
          },
          type: {
            typesSelectedOptions,
            typesSetSelectedOptions,
          },
          org: {
            orgsSelectedOptions,
            orgsSetSelectedOptions,
          },
          subject: {
            subjectsSelectedOptions,
            subjectsSetSelectedOptions,
          },
          region: {
            regionsSelectedOptions,
            regionsSetSelectedOptions,
          },
          publishedAtStart: {
            bindPublishedAtStart,
            publishedAtStart,
            setPublishedAtStart,
          },
          publishedAtEnd: {
            bindPublishedAtEnd,
            publishedAtEnd,
            setPublishedAtEnd,
          },
          eventDate: {
            bindEventDate,
            eventDate,
            setEventDate,
          },
        },
        search,
        subjects,
        setSubjects,
        regions,
        setRegions,
        postIndexType,
        setPostIndexType,
        isDialogOpen,
        setIsDialogOpen,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export const useSearch = () => {
  const context = useContext(SearchContext)
  if (context === undefined) {
    throw new Error('useSearch can only be used inside SearchProvider')
  }
  return context
}

export default SearchProvider
