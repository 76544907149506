import { useState, Fragment, useEffect, useCallback } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/react/solid'
import { useRouter } from 'next/router'

const ComboboxInput = ({
  options,
  labelField = 'name',
  localised = true,
  label = 'Field',
  selectedOptions,
  setSelectedOptions,
  searchFunc = false,
}) => {
  const { locale } = useRouter()
  const [query, setQuery] = useState('')
  const [filteredOpts, setFilteredOpts] = useState(options)

  useEffect(() => {
    let filteredOptions = [...options]
    if (query !== '' && !searchFunc) {
      if (labelField === 1) {
        filteredOptions = options.filter((option) => {
          return option[labelField].toLowerCase().includes(query.toLowerCase())
        })
      } else {
        filteredOptions = options.filter((option) => {
          if (localised) {
            return option[labelField][locale]
              .toLowerCase()
              .includes(query.toLowerCase())
          }
          return option[labelField].toLowerCase().includes(query.toLowerCase())
        })
      }
      setFilteredOpts(filteredOptions)
    } else if (query !== '' && typeof searchFunc === 'function') {
      search()
    }
  }, [query, labelField, locale, options, searchFunc, search, localised])

  const search = useCallback(async () => {
    const results = await searchFunc(query)
    if (results) {
      setFilteredOpts(results)
    }
  }, [query, searchFunc])

  const unselectOption = (e, i) => {
    e.preventDefault()
    const newOpts = [...selectedOptions]
    newOpts.splice(i, 1)
    setSelectedOptions(newOpts)
  }

  return (
    <Combobox
      value={selectedOptions}
      onChange={setSelectedOptions}
      multiple
      as="div"
      className="relative mb-4 w-full rounded border border-primary-200 shadow focus:outline-none"
    >
      <Combobox.Label className="block py-1 px-2.5 text-sm font-medium">
        {label}
      </Combobox.Label>
      <div className="relative">
        {selectedOptions.length > 0 && (
          <ul className="flex flex-wrap border-b border-primary-200 px-2 pb-1">
            {selectedOptions.map((option, i) => (
              <li
                key={labelField === 1 ? option[0] : option._id}
                className="mr-2 mb-1 flex flex-row items-center rounded bg-accent-100 text-sm"
              >
                <span className="px-2 pt-0.5">
                  {labelField === 1
                    ? option[labelField]
                    : localised
                    ? option[labelField][locale]
                    : option[labelField]}
                </span>
                <a
                  onClick={(e) => unselectOption(e, i)}
                  className="flex h-full w-5 cursor-pointer items-center justify-center rounded-tr rounded-br bg-accent-200 text-accent-700"
                >
                  <XIcon />
                </a>
              </li>
            ))}
          </ul>
        )}
        <div className="relative m-0.5 cursor-default bg-white text-left sm:text-sm">
          <Combobox.Input
            onChange={(event) => setQuery(event.target.value)}
            className="w-full rounded border border-primary-100 bg-white py-2 pl-3 pr-10 text-sm leading-5 text-primary-500 focus:ring-0"
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-primary-500 ring-opacity-25 focus:outline-none sm:text-sm">
            {filteredOpts.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none py-2 px-4 text-primary-400">
                Nothing found.
              </div>
            ) : (
              filteredOpts.map((option) => (
                <Combobox.Option
                  key={labelField === 1 ? option[0] : option._id}
                  value={option}
                  className={({ active }) =>
                    `pr-4text-primary-500 relative cursor-default select-none py-2 pl-10  ${
                      active ? 'bg-primary-100' : 'bg-white'
                    }`
                  }
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {labelField === 1
                          ? option[labelField]
                          : localised
                          ? option[labelField][locale]
                          : option[labelField]}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? 'text-accent-500' : 'text-accent-500'
                          }`}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}
export default ComboboxInput
