import { useEffect, useRef } from 'react'
import { i18n } from '@lingui/core'
import { useRouter } from 'next/router'
import Head from 'next/head'
import Layout from 'components/Layout'
import { initTranslation } from '@lib/i18n'
import '../styles/styles.css'
import { I18nProvider } from '@lingui/react'
import SearchProvider from 'providers/SearchProvider'
import TranslationProvider from 'providers/TranslationsProvider'

initTranslation(i18n)

function IdpcApp({ Component, pageProps }) {
  const router = useRouter()
  const locale = router.locale || router.defaultLocale
  const firstRender = useRef(true)

  if (pageProps.translation && firstRender.current) {
    i18n.load(locale, pageProps.translation)
    i18n.activate(locale)
    firstRender.current = false
  }

  useEffect(() => {
    if (pageProps.translation) {
      i18n.load(locale, pageProps.translation)
      i18n.activate(locale)
    }
  }, [locale, pageProps.translation])

  return (
    <I18nProvider i18n={i18n}>
      <SearchProvider>
        <TranslationProvider>
          <Layout>
            <Head>
              <meta
                name="viewport"
                content="initial-scale=1.0, width=device-width"
                key="viewport"
              />
            </Head>
            <Component {...pageProps} />
          </Layout>
        </TranslationProvider>
      </SearchProvider>
    </I18nProvider>
  )
}

export default IdpcApp
