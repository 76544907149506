import SiteHeaderEn from './en/SiteHeader'
import SiteHeaderFr from './fr/SiteHeader'
import SiteHeaderEs from './es/SiteHeader'

const items = {
  en: SiteHeaderEn,
  fr: SiteHeaderFr,
  es: SiteHeaderEs,
}

export default items
