import clsx from 'clsx'

export const variants = {
  primary: 'bg-primary-500 text-white hover:bg-primary-600 hover:text-white',
  secondary: 'bg-accent-500 text-white hover:bg-accent-600 hover:text-white',
}

const FormButton = ({ variant = 'primary', children }) => {
  return (
    <button
      className={clsx(
        'flex items-center justify-center rounded-full border border-transparent px-5 py-2 text-center text-base font-medium',
        'md:py-2 md:px-5',
        variants[variant]
      )}
    >
      {children}
    </button>
  )
}

export default FormButton
