import SiteFooterEn from './en/SiteFooter'
import SiteFooterFr from './fr/SiteFooter'
import SiteFooterEs from './es/SiteFooter'

const items = {
  en: SiteFooterEn,
  fr: SiteFooterFr,
  es: SiteFooterEs,
}

export default items
