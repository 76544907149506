import {
  en as pluralsEn,
  fr as pluralsFr,
  es as pluralsEs,
} from 'make-plural/plurals'
import { format } from 'date-fns'
import { enGB as en, fr, es } from 'date-fns/locale'
import { t, defineMessage } from '@lingui/macro'

export const locales = { en, fr, es }
export const localeLabels = {
  en: defineMessage({ message: 'English' }),
  fr: defineMessage({ message: 'French' }),
  es: defineMessage({ message: 'Spanish' }),
}

export function initTranslation(i18n) {
  i18n.loadLocaleData({
    en: { plurals: pluralsEn },
    fr: { plurals: pluralsFr },
    es: { plurals: pluralsEs },
  })
}

export async function loadTranslation(locale, isProduction = true) {
  let data
  if (isProduction) {
    data = await import(`../locales/${locale}/messages`)
  } else {
    data = await import(`@lingui/loader!../locales/${locale}/messages.po`)
  }
  return data.messages
}

export const isSameDate = (firstDate, secondDate) => {
  if (
    format(new Date(firstDate), 'yyyy-mm-dd') ===
    format(new Date(secondDate), 'yyyy-mm-dd')
  ) {
    return true
  }
  return false
}

export const formatDate = (date, formatStr = 'PP', locale = 'en') => {
  return format(date, formatStr, {
    locale: locales[locale],
  })
}
