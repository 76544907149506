const items = [
  {
    name: 'IDPC',
    submenu: [
      {
        name: 'About & Mission',
        href: '/[[...slug]]',
        as: '/about',
      },
      {
        name: 'Governance',
        href: '/team',
        as: '/team',
      },
      {
        name: 'Jobs Board',
        href: '/jobs',
        as: '/jobs',
      },
      {
        name: 'Privacy Policy',
        href: '/[[...slug]]',
        as: '/privacy-policy',
      },
      {
        name: 'Contact',
        href: '/[[...slug]]',
        as: '/contact',
      },
    ],
  },
  {
    name: 'Global Network',
    submenu: [
      {
        name: 'Membership',
        href: '/members',
        as: '/members',
      },
      {
        name: 'Publications',
        href: '/publications',
        as: '/publications',
      },
      {
        name: 'Events',
        href: '/events',
        as: '/events',
      },
    ],
  },
  {
    name: 'Our Work',
    submenu: [
      {
        name: 'UN Level Engagement',
        href: '/[[...slug]]',
        as: '/our-work/un-level-engagement',
      },
      {
        name: 'Regional & National Advocacy',
        href: '/[[...slug]]',
        as: '/our-work/regional-national-advocacy',
      },
      {
        name: 'Learning Centre',
        href: '/[[...slug]]',
        as: '/our-work/learning-centre',
      },
      {
        name: 'Strategic Plan',
        href: '/[[...slug]]',
        as: '/our-work/strategic-plan',
      },
    ],
  },
  {
    name: 'Themes',
    submenu: [
      {
        name: 'Human Rights',
        href: '/[[...slug]]',
        as: '/themes/human-rights',
      },
      {
        name: 'Decriminalisation',
        href: '/[[...slug]]',
        as: '/themes/decriminalisation',
      },
      {
        name: 'Gender',
        href: '/[[...slug]]',
        as: '/themes/gender',
      },
      {
        name: 'Health',
        href: '/[[...slug]]',
        as: '/themes/health',
      },
      {
        name: 'Development',
        href: '/[[...slug]]',
        as: '/themes/development',
      },
    ],
  },
  {
    name: 'Newsroom',
    submenu: [
      {
        name: 'News',
        href: '/news',
        as: '/news',
      },
      {
        name: 'Blog',
        href: '/blog',
        as: '/blog',
      },
      {
        name: 'IDPC in the Media',
        href: '/media',
        as: '/media',
      },
      {
        name: 'Press Releases',
        href: '/press',
        as: '/press',
      },
      {
        name: 'Newsletter',
        href: '/[[...slug]]',
        as: '/newsroom/newsletter',
      },
      {
        name: 'Jobs Board',
        href: '/jobs',
        as: '/jobs',
      },
    ],
  },
]
export default items
