import { t } from '@lingui/macro'
import { format } from 'date-fns'

export const postTypes = {
  publications: t`Publications`,
  news: t`News`,
  blog: t`Blog`,
  events: t`Events`,
  media: t`IDPC in the Media`,
  press: t`Press Releases`,
}

const postTypeTitles = {
  publications_: t`Publications`,
  publications_idpc: t`IDPC Publications`,
  publications_members: t`Publications from IDPC Members`,
  news_: t`News`,
  blog_: t`Blog`,
  events_: t`Events`,
  events_past: t`Past Events`,
  media_: t`IDPC in the Media`,
  press_: t`Press Releases`,
  search_: t`Search Results`,
}

export const postType = (value = 'news') => {
  return postTypes[value]
}

export const postTypeTitle = (value = 'news') => {
  return postTypeTitles[value]
}

export const removeDuplicates = (arr) => {
  const ids = arr.map((o) => o._id)
  const filtered = arr.filter(({ _id }, index) => !ids.includes(_id, index + 1))
  return filtered
}

export const absPostUrl = (post) => {
  const publishedAt = new Date(post.publishedAt)
  const url = `/${post.type}/${format(publishedAt, 'yyyy')}/${format(
    publishedAt,
    'MM'
  )}/${post.slug.current}`
  return `${process.env.NEXT_PUBLIC_DOMAIN}${
    post.locale !== 'en' ? `/${post.locale}` : ``
  }${url}`
}

export const absPageUrl = (page) => {
  return `${process.env.NEXT_PUBLIC_DOMAIN}${
    page.locale && page.locale !== 'en' ? `/${page.locale}` : ``
  }/${page.slug.current}`
}
