import Link from 'next/link'
import { useRouter } from 'next/router'
import { t, Trans } from '@lingui/macro'
import IdpcLogo from '../../public/svg/idpcLogo.svg'
import FacebookLogo from '../../public/svg/facebookLogo.svg'
import LinkedInLogo from '../../public/svg/linkedInLogo.svg'
import TwitterLogo from '../../public/svg/twitterLogo.svg'
import YouTubeLogo from '../../public/svg/youTubeLogo.svg'
import MenuItems from '@lib/menus/SiteFooter'
import SubscribeForm from 'components/SubscribeForm'

const SiteFooter = () => {
  const { locale } = useRouter()
  const year = new Date().getFullYear()
  return (
    <footer>
      <div className="bg-accent-400">
        <div className="container py-6 md:py-10">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
            <div className="md:max-w-md md:text-xl">
              <Trans>
                Keep up-to-date with drug policy developments by subscribing to
                the <strong>IDPC Monthly Alert</strong>.
              </Trans>
            </div>
            <div>
              <SubscribeForm />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden bg-primary-500 text-white lg:block">
        <div className="container py-6">
          <div className="grid grid-cols-2 gap-10 md:grid-cols-3 lg:grid-cols-5">
            {MenuItems[locale].map((item, i) => (
              <div className="mb-6" key={item.name}>
                <h5 className="mb-4 text-lg font-medium">{item.name}</h5>
                <ul>
                  {item.submenu.map((submenuItem) => (
                    <li className="mb-2" key={submenuItem.name}>
                      <Link
                        href={
                          submenuItem.href ? submenuItem.href : `/[[...slug]]`
                        }
                        as={submenuItem.as}
                      >
                        <a className="block font-light leading-tight text-white hover:text-accent-500">
                          {submenuItem.name}
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="border-t border-t-primary-200 bg-gradient-to-b from-[#C0D9EB] to-[#FCE4DF]">
        <div className="container py-6 lg:py-20">
          <div className="grid gap-10 md:grid-cols-2">
            <div className="align-center mb-6">
              <div className="mb-2">
                <IdpcLogo className="w-16 lg:w-24" fill="#005B9B" />
              </div>
              <p className="mb-4 text-xl font-medium leading-tight text-primary-500 lg:text-2xl">
                International Drug Policy Consortium
              </p>
              <ul className="flex flex-row">
                <li className="">
                  <a
                    href={process.env.NEXT_PUBLIC_SOCIAL_FACEBOOK}
                    target="_blank"
                    rel="noreferrer"
                    className="block p-2"
                  >
                    <FacebookLogo width={36} height={36} fill="#005B9B" />
                  </a>
                </li>
                <li className="ml-4">
                  <a
                    href={process.env.NEXT_PUBLIC_SOCIAL_TWITTER}
                    target="_blank"
                    rel="noreferrer"
                    className="block p-2"
                  >
                    <TwitterLogo width={36} height={36} fill="#005B9B" />
                  </a>
                </li>
                <li className="ml-4">
                  <a
                    href={process.env.NEXT_PUBLIC_SOCIAL_LINKEDIN}
                    target="_blank"
                    rel="noreferrer"
                    className="block p-2"
                  >
                    <LinkedInLogo width={36} height={36} fill="#005B9B" />
                  </a>
                </li>
                <li className="ml-4">
                  <a
                    href={process.env.NEXT_PUBLIC_SOCIAL_YOUTUBE}
                    target="_blank"
                    rel="noreferrer"
                    className="block p-2"
                  >
                    <YouTubeLogo width={36} height={36} fill="#005B9B" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="mb-6">
              <h6 className="mb-4 text-base font-medium uppercase text-primary-500">
                <Trans>Contact</Trans>
              </h6>
              <p className="mb-2 text-primary-500">
                <Trans>
                  For general queries please email us at:{' '}
                  <a
                    href="mailto:contact@idpc.net"
                    className="border-b-2 border-accent-500"
                  >
                    contact@idpc.net
                  </a>
                  .
                </Trans>
              </p>
              <p className="mb-2 text-primary-500">
                <Link
                  href="/[[...slug]]"
                  as={
                    locale === 'en'
                      ? `/contact`
                      : locale === 'fr'
                      ? `/contact-fr`
                      : `/contacto`
                  }
                  locale={locale}
                >
                  <a className="border-b-2 border-accent-500">
                    <Trans>For more info see our contact page.</Trans>
                  </a>
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-black p-4 text-center text-accent-200">
          &copy; {year} IDPC. Web design by Studio Odilo Girod and{' '}
          <a
            className="text-accent-200"
            href="https://martinpetts.com"
            target="_blank"
            rel="noreferrer"
          >
            Martin Petts
          </a>
          .{' '}
          <a
            className="text-accent-200"
            href="https://martinpetts.com/"
            target="_blank"
            rel="noreferrer"
          >
            Built and maintained by Martin Petts.
          </a>
        </div>
      </div>
    </footer>
  )
}
export default SiteFooter
