import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslations } from 'providers/TranslationsProvider'

const I18nSwitcher = () => {
  const { pathname, asPath } = useRouter()
  const { translations, locales } = useTranslations()

  const getAs = (locale) => {
    if (translations[locale]) {
      return translations[locale]
    }
    return '/'
  }

  const getHref = () => {
    if (pathname.indexOf('/page/[page]')) {
      return asPath
    }
    return pathname
  }

  return (
    <ul className="xl:border-color-white grid grid-cols-3 xl:grid-cols-1 xl:divide-y xl:divide-white xl:border-l">
      {locales.map((l) => (
        <li className="xl:text-xs" key={`locale-${l.locale}`}>
          <Link href={getHref()} as={getAs(l.locale)} locale={l.locale}>
            <a className="block px-4 py-4 text-center hover:text-accent-500 xl:px-6 xl:py-0.5 xl:text-white">
              {l.label}
            </a>
          </Link>
        </li>
      ))}
    </ul>
  )
}
export default I18nSwitcher
