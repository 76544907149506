import { createContext, useContext, useState } from 'react'
import { format } from 'date-fns'
import { useRouter } from 'next/router'

export const TranslationContext = createContext()

const TranslationProvider = ({ children }) => {
  const router = useRouter()
  const [isReady, setIsReady] = useState(false)
  const [translations, setTranslations] = useState({})

  const locales = [
    {
      locale: 'en',
      label: 'ENG',
    },
    { locale: 'fr', label: 'FRA' },
    { locale: 'es', label: 'ESP' },
  ]

  const init = (obj = false) => {
    const items = {}

    if (obj.translations) {
      obj.translations.forEach((translation) => {
        if (translation.slug?.current) {
          switch (obj._type) {
            case 'page':
            default:
              items[translation.locale] = translation.slug.current
              break

            case 'post':
              const publishedAt = new Date(translation.publishedAt)
              items[translation.locale] = `${translation.type}/${format(
                publishedAt,
                'yyyy'
              )}/${format(publishedAt, 'MM')}/${translation.slug.current}`
              break
          }
        }
      })
    } else if (!obj) {
      locales.map((locale) => {
        items[locale.locale] = router.asPath
      })
    }
    setTranslations(items)
    setIsReady(true)
  }

  const headLinks = () => {
    return locales.map((locale) => {
      if (translations[locale.locale]) {
        // regex removes any preceeding slash
        return (
          <link
            key={`translation-${locale.locale}`}
            rel="alternate"
            hreflang={locale.locale}
            href={`${process.env.NEXT_PUBLIC_DOMAIN}/${
              locale.locale
            }/${translations[locale.locale].replace(/^\/+/, '')}`}
          />
        )
      }
    })
  }

  return (
    <TranslationContext.Provider
      value={{
        translations,
        init,
        isReady,
        locales,
        headLinks,
      }}
    >
      {children}
    </TranslationContext.Provider>
  )
}

export const useTranslations = () => {
  const context = useContext(TranslationContext)
  if (context === undefined) {
    throw new Error(
      'useTranslations can only be used inside TranslationProvider'
    )
  }
  return context
}

export default TranslationProvider
