const FormDateInput = ({ label = false, bind = false, placeholder = null }) => {
  return (
    <div className="relative mb-4 w-full">
      {label && (
        <label className="block py-1 text-sm font-medium">{label}</label>
      )}
      <div className="relative cursor-default text-left sm:text-sm">
        <input
          type="date"
          className="w-full rounded border border-primary-300 bg-white bg-opacity-50 py-2 pl-3 pr-10 text-sm leading-5 text-primary-500 shadow focus:ring-0"
          placeholder={placeholder}
          {...bind}
        />
      </div>
    </div>
  )
}

export default FormDateInput
