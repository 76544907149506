import { Fragment, useState } from 'react'
import { t, Trans } from '@lingui/macro'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import useEmailInput from 'hooks/emailInputHook'
import FormInput from 'components/ui/FormInput'
import FormCheckbox from 'components/ui/FormCheckbox'
import FormButton from 'components/ui/FormButton'

const SubscribeModal = ({
  isDialogOpen,
  setIsDialogOpen,
  title = 'Success',
  message = 'The action was successful.',
}) => {
  return (
    <Transition appear show={isDialogOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => setIsDialogOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-96 transform bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex flex-row justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-primary-500"
                  >
                    {title}
                  </Dialog.Title>
                  <a
                    className="-mt-2 -mr-2 block h-8 w-8 cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault()
                      setIsDialogOpen(false)
                    }}
                  >
                    <XIcon />
                  </a>
                </div>
                <div className="mt-2">{message}</div>
                <div className="mt-4 flex flex-row justify-center">
                  <button
                    type="button"
                    className="flex items-center justify-center rounded-full border border-transparent bg-primary-500 px-6 py-2 text-center text-base font-medium text-white hover:bg-primary-600 hover:text-white"
                    onClick={() => setIsDialogOpen(false)}
                  >
                    <Trans>OK</Trans>
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

const SubscribeForm = () => {
  const [isWorking, setIsWorking] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [errors, setErrors] = useState([])
  const [subscribeEn, setSubscribeEn] = useState(false)
  const [subscribeFr, setSubscribeFr] = useState(false)
  const [subscribeEs, setSubscribeEs] = useState(false)
  const [subscribeAsia, setSubscribeAsia] = useState(false)
  const {
    value: email,
    bind: bindEmail,
    showError: showEmailError,
    reset: resetEmail,
    isValid: isEmailValid,
  } = useEmailInput('')

  const resetCheckboxes = () => {
    setSubscribeEn(false)
    setSubscribeFr(false)
    setSubscribeEs(false)
    setSubscribeAsia(false)
  }

  const subscribe = async () => {
    const lists = []
    if (subscribeEn) {
      lists.push('EN')
    }
    if (subscribeFr) {
      lists.push('FR')
    }
    if (subscribeEs) {
      lists.push('ES')
    }
    if (subscribeAsia) {
      lists.push('ASIA')
    }
    if (lists.length) {
      const result = await fetch('/api/newsletter', {
        method: 'PUT',
        body: JSON.stringify({
          email,
          lists,
        }),
      }).then((res) => {
        return res
      })
      if (result.ok) {
        return true
      }
    }
    return false
  }

  const formSubmit = (e) => {
    e.preventDefault()
    setIsWorking(true)

    const errs = []
    if (!isEmailValid) {
      errs.push(
        <p key="form-error-email" className="text-accent-500">
          <Trans>Please enter a valid email address.</Trans>
        </p>
      )
    }
    if (!subscribeEn && !subscribeFr && !subscribeEs && !subscribeAsia) {
      errs.push(
        <p key="form-error-checkboxes" className="text-accent-500">
          <Trans>Please select a newsletter to subscribe to.</Trans>
        </p>
      )
    }

    if (errs.length) {
      setErrors(errs)
      setIsWorking(false)
      setIsDialogOpen(true)
    } else {
      const result = subscribe()
      if (result) {
        resetEmail()
        resetCheckboxes()
        setErrors([])
      } else {
        const errs = [
          <p key="form-error-mailchimp" className="text-accent-500">
            <Trans>We encountered an error with the subscription.</Trans>
          </p>,
        ]
        setErrors(errs)
      }
      setIsWorking(false)
      setIsDialogOpen(true)
    }
  }

  const modalTitle = () => {
    if (isWorking) {
      return t`Please wait...`
    }
    if (errors.length) {
      return t`Sorry, there was a problem`
    }
    return t`Success!`
  }

  const modalMessage = () => {
    if (isWorking) {
      return null
    }
    if (errors.length) {
      return <div>{errors}</div>
    }
    return t`Please check your inbox to complete your subscription.`
  }

  return (
    <form onSubmit={formSubmit}>
      <FormInput
        label={t`Please provide your email address`}
        bind={bindEmail}
      />
      {showEmailError && (
        <div className="mb-2 bg-accent-600 py-1 px-2 text-sm text-white">
          <Trans>Please ensure your email address is correct.</Trans>
        </div>
      )}
      <p className="pb-2 text-sm">
        <Trans>
          Please select which newsletters you would like to subscibe to.
        </Trans>
      </p>
      <p className="text-sm font-medium">
        <Trans>The IDPC Monthly Alert</Trans>
      </p>
      <div className="grid grid-cols-3 gap-4 py-2">
        <FormCheckbox
          label={t`English`}
          value={subscribeEn}
          onChange={setSubscribeEn}
        />
        <FormCheckbox
          label={t`French`}
          value={subscribeFr}
          onChange={setSubscribeFr}
        />
        <FormCheckbox
          label={t`Spanish`}
          value={subscribeEs}
          onChange={setSubscribeEs}
        />
      </div>
      <div className="py-2">
        <FormCheckbox
          label={t`Asia-Pacific Weekly Digest`}
          value={subscribeAsia}
          onChange={setSubscribeAsia}
        />
      </div>
      <FormButton variant="primary">
        {isWorking && (
          <svg
            role="status"
            className="mr-3 inline h-4 w-4 animate-spin text-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#E5E7EB"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentColor"
            />
          </svg>
        )}
        <Trans>Subscribe</Trans>
      </FormButton>
      <SubscribeModal
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        title={modalTitle()}
        message={modalMessage()}
      />
    </form>
  )
}

export default SubscribeForm
