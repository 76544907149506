// https://rangle.io/blog/simplifying-controlled-inputs-with-hooks/
import validator from 'email-validator'
import { useCallback, useState } from 'react'

const useEmailInput = (initialValue) => {
  const [value, setValue] = useState(initialValue)
  const [isValid, setIsValid] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [showError, setShowError] = useState(false)

  const validateEmail = (email) => {
    if (validator.validate(email)) {
      setIsValid(true)
      setShowError(false)
    } else {
      setIsValid(false)
    }
  }

  const handleChange = useCallback((email) => {
    if (email !== '') {
      setIsDirty(true)
    } else {
      setIsDirty(false)
    }
    setValue(email)
    validateEmail(email)
  }, [])

  return {
    value,
    setValue,
    isValid,
    isDirty,
    setIsDirty,
    showError,
    setShowError,
    handleChange,
    reset: () => {
      setValue('')
      setIsValid(false)
      setIsDirty(false)
      setShowError(false)
    },
    bind: {
      value,
      onChange: (event) => handleChange(event.target.value),
      onBlur: () => {
        if (!isValid && isDirty) {
          setShowError(true)
        } else {
          setShowError(false)
        }
      },
    },
  }
}

export default useEmailInput
