import { Fragment, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { getClient } from '@lib/sanity'
import { groq } from 'next-sanity'
import { t, Trans } from '@lingui/macro'
import { postTypes } from '@lib/utils'
import ComboboxInput from 'components/ui/ComboboxInput'
import FormInput from 'components/ui/FormInput'
import FormDateInput from 'components/ui/FormDateInput'
import { useSearch } from 'providers/SearchProvider'

const PostFilter = ({ isOpen, setIsOpen }) => {
  const router = useRouter()
  const {
    filters: {
      text: { setTextQuery, bindTextQuery },
      type: { typesSelectedOptions, typesSetSelectedOptions },
      org: { orgsSelectedOptions, orgsSetSelectedOptions },
      subject: { subjectsSelectedOptions, subjectsSetSelectedOptions },
      region: { regionsSelectedOptions, regionsSetSelectedOptions },
      publishedAtStart: { setPublishedAtStart, bindPublishedAtStart },
      publishedAtEnd: { setPublishedAtEnd, bindPublishedAtEnd },
    },
    search,
    subjects,
    regions,
    setSubjects,
    setRegions,
    isDialogOpen,
    setIsDialogOpen,
  } = useSearch()

  useEffect(() => {
    if (router.isReady && subjects.length && regions.length) {
      if (router.query.subject) {
        const selectedSubjectIds = Array.isArray(router.query.subject)
          ? [...router.query.subject]
          : [router.query.subject]
        const selectedSubjects = subjects.filter((s) =>
          selectedSubjectIds.includes(s._id)
        )
        subjectsSetSelectedOptions(selectedSubjects)
      }
      if (router.query.region) {
        const selectedRegionIds = Array.isArray(router.query.region)
          ? [...router.query.region]
          : [router.query.region]
        const selectedRegions = regions.filter((r) =>
          selectedRegionIds.includes(r._id)
        )
        regionsSetSelectedOptions(selectedRegions)
      }
    }
  }, [
    router,
    subjects,
    regions,
    subjectsSetSelectedOptions,
    regionsSetSelectedOptions,
  ])

  useEffect(() => {
    const groqQuery = groq`{
      "subjects": *[_type == "subject"],
      "regions": *[_type == "region"],
    }`
    getClient(false)
      .fetch(groqQuery)
      .then((data) => {
        setSubjects(data.subjects)
        setRegions(data.regions)
      })
      .catch(console.error)
  }, [setSubjects, setRegions])

  const handleFormSubmit = (e) => {
    e.preventDefault()
    search()
  }

  // TODO: At the moment you can only search orgs which start with query
  const searchOrgs = async (query) => {
    if (query.length > 3) {
      const groqQuery = groq`*[_type in ["member", "organisation", "teamMember", "person"] && name match "${query}*"][0...10]`
      return await getClient(false).fetch(groqQuery).catch(console.error)
    }
    return []
  }

  const handleClearForm = (e) => {
    e.preventDefault()
    setTextQuery('')
    typesSetSelectedOptions([])
    orgsSetSelectedOptions([])
    subjectsSetSelectedOptions([])
    regionsSetSelectedOptions([])
    setPublishedAtStart('')
    setPublishedAtEnd('')
  }

  return (
    <Transition appear show={isDialogOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => setIsDialogOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex flex-row justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-primary-500"
                  >
                    Search the library
                  </Dialog.Title>
                  <a
                    className="-mt-2 -mr-2 block h-8 w-8 cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault()
                      setIsDialogOpen(false)
                    }}
                  >
                    <XIcon />
                  </a>
                </div>
                <div className="mt-2">
                  <form onSubmit={handleFormSubmit}>
                    <FormInput label={t`Search text`} bind={bindTextQuery} />
                    <ComboboxInput
                      options={Object.entries(postTypes)}
                      labelField={1}
                      label={t`Post types`}
                      selectedOptions={typesSelectedOptions}
                      setSelectedOptions={typesSetSelectedOptions}
                    />
                    <ComboboxInput
                      options={[]}
                      labelField="name"
                      localised={false}
                      label={t`Members, authors and organisations`}
                      selectedOptions={orgsSelectedOptions}
                      setSelectedOptions={orgsSetSelectedOptions}
                      searchFunc={searchOrgs}
                    />
                    <ComboboxInput
                      options={subjects}
                      labelField="title"
                      label={t`Subjects`}
                      selectedOptions={subjectsSelectedOptions}
                      setSelectedOptions={subjectsSetSelectedOptions}
                    />
                    <ComboboxInput
                      options={regions}
                      labelField="title"
                      label={t`Regions`}
                      selectedOptions={regionsSelectedOptions}
                      setSelectedOptions={regionsSetSelectedOptions}
                    />
                    <div className="grid grid-cols-2 gap-4">
                      <FormDateInput
                        label={t`Published start`}
                        bind={bindPublishedAtStart}
                      />
                      <FormDateInput
                        label={t`Published end`}
                        bind={bindPublishedAtEnd}
                      />
                    </div>
                    <div className="mt-4 flex flex-row justify-start">
                      <button
                        type="submit"
                        className="flex items-center justify-center rounded-full border border-transparent bg-primary-500 px-8 py-3 text-center text-base font-medium text-white hover:bg-primary-600 hover:text-white md:py-3 md:px-10 md:text-lg"
                      >
                        <Trans>Search</Trans>
                      </button>
                      <button
                        className="ml-4 text-primary-400"
                        onClick={handleClearForm}
                      >
                        <Trans>Clear filters</Trans>
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
export default PostFilter
