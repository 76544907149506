import { Fragment } from 'react'
import { Popover, Transition, Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon, SearchIcon } from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Trans } from '@lingui/macro'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useSearch } from 'providers/SearchProvider'
import I18nSwitcher from 'components/I18nSwitcher'
import IdpcLogo from '../../public/svg/idpcLogo.svg'
import MenuItems from '@lib/menus/SiteHeader'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SiteHeader = () => {
  const { locale } = useRouter()
  const { setIsDialogOpen } = useSearch()
  return (
    <Popover className="sticky top-0 z-30 bg-primary-500 pr-6 xl:pr-0">
      <div className="">
        <div className="flex items-center justify-between xl:justify-start xl:space-x-10">
          <div className="flex justify-start xl:w-0 xl:flex-1">
            <Link href="/">
              <a className="block px-6 py-2">
                <span className="sr-only">IDPC</span>
                <IdpcLogo className="h-12" fill="#FFFFFF" />
              </a>
            </Link>
          </div>
          <div className="-my-2 -mr-2 flex flex-row xl:hidden">
            <a
              className="block cursor-pointer py-3 px-4 text-white hover:text-primary-100"
              onClick={(e) => {
                e.preventDefault()
                setIsDialogOpen(true)
              }}
            >
              <SearchIcon className="mr-2 h-6 w-6" />
            </a>
            <Popover.Button className="inline-flex items-center justify-center rounded-md p-2 text-primary-100 hover:text-accent-500 focus:outline-none focus:ring-2 focus:ring-inset">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden xl:block">
            <ul className="flex space-x-10">
              {MenuItems[locale].map((menuItem, k) => (
                <Fragment key={`menu-item-${k}-${menuItem.name}`}>
                  {menuItem.submenu ? (
                    <Popover as="li" className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            as="a"
                            className={classNames(
                              open ? 'text-accent' : 'text-white',
                              'group inline-flex cursor-pointer items-center bg-transparent font-medium text-white hover:text-accent-500 focus:text-accent-500 focus:outline-none active:text-accent-500 2xl:text-lg'
                            )}
                          >
                            <span>{menuItem.name}</span>
                            <ChevronDownIcon
                              className={classNames(
                                open
                                  ? 'rotate-180 text-accent-500'
                                  : 'text-white',
                                'ml-2 h-5 w-5 group-hover:text-accent-500'
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-xs transform xl:left-1/2 xl:ml-0 xl:-translate-x-1/2">
                              {({ close }) => (
                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                  <ul className="relative bg-primary-100 py-4">
                                    {menuItem.submenu.map((submenuItem, i) => (
                                      <Fragment
                                        key={`submenu-item-${i}-${submenuItem.name}`}
                                      >
                                        {!!submenuItem.items ? (
                                          <li>
                                            {submenuItem.name && (
                                              <div className="py-3 px-6 uppercase text-primary-400">
                                                {submenuItem.name}
                                              </div>
                                            )}
                                            <ul>
                                              {submenuItem.items.map(
                                                (submenuItemItem, j) => (
                                                  <li
                                                    key={`submenu-item-${j}-${submenuItemItem.name}`}
                                                  >
                                                    <Link
                                                      href={
                                                        submenuItemItem.href
                                                      }
                                                      as={submenuItemItem.as}
                                                    >
                                                      <a
                                                        onClick={() => close()}
                                                        className="block py-2 px-6 text-primary-500 hover:bg-accent-500 hover:text-white"
                                                      >
                                                        <span className="block text-base font-medium">
                                                          {submenuItemItem.name}
                                                        </span>
                                                      </a>
                                                    </Link>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </li>
                                        ) : (
                                          <li>
                                            <Link
                                              key={submenuItem.name}
                                              href={submenuItem.href}
                                              as={submenuItem.as}
                                            >
                                              <a
                                                onClick={() => close()}
                                                className="-m-3 flex items-start rounded-lg px-6 py-3 text-primary-500 hover:bg-accent-500 hover:text-white"
                                              >
                                                <div className="ml-4">
                                                  <p className="text-base font-medium">
                                                    {submenuItem.name}
                                                  </p>
                                                </div>
                                              </a>
                                            </Link>
                                          </li>
                                        )}
                                      </Fragment>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  ) : (
                    <li>
                      <Link href={menuItem.href} as={menuItem.as}>
                        <a className="font-medium text-white hover:text-accent-500 2xl:text-lg">
                          {menuItem.name}
                        </a>
                      </Link>
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>
          </Popover.Group>
          <div className="hidden items-center justify-end xl:flex xl:w-0 xl:flex-1">
            <a
              className="block cursor-pointer py-4 px-8 text-white hover:text-accent-500"
              onClick={(e) => {
                e.preventDefault()
                setIsDialogOpen(true)
              }}
            >
              <SearchIcon className="h-7 w-7" />
            </a>
            <I18nSwitcher />
          </div>
        </div>
      </div>

      <Popover.Panel
        focus
        className="fixed inset-x-0 top-0 bottom-0 z-40 origin-top-right transform p-2 transition xl:hidden"
      >
        {({ close }) => (
          <div className="absolute top-2 bottom-2 left-2 right-2 divide-y-2 divide-gray-50 overflow-auto overscroll-none rounded-lg bg-white pb-10 shadow-lg ring-1 ring-black ring-opacity-5 md:left-1/2">
            <Popover.Button className="fixed top-4 right-4 inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-100">
              <span className="sr-only">Close menu</span>
              <XIcon className="h-6 w-6 bg-white" aria-hidden="true" />
            </Popover.Button>
            <div className="px-4 pt-4 pb-6">
              <nav>
                <ul>
                  {MenuItems[locale].map((menuItem, k) => (
                    <Fragment key={`menu-item-${k}-${menuItem.name}`}>
                      {menuItem.submenu ? (
                        <Disclosure
                          as="li"
                          className="border-b border-b-primary-100"
                        >
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex w-full justify-between rounded-lg px-4 py-2 text-left text-primary-400 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                                <span>{menuItem.name}</span>
                                <ChevronDownIcon
                                  className={`${
                                    open ? 'rotate-180 transform' : ''
                                  } h-5 w-5 text-primary-400`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel as="ul" className="pl-4">
                                {menuItem.submenu.map((submenuItem, i) => (
                                  <Fragment
                                    key={`submenu-item-${i}-${submenuItem.name}`}
                                  >
                                    {!!submenuItem.items ? (
                                      <li>
                                        {submenuItem.name && (
                                          <span className="py-3 px-4 uppercase text-primary-400">
                                            {submenuItem.name}
                                          </span>
                                        )}
                                        <ul className="pb-2">
                                          {submenuItem.items.map(
                                            (submenuItemItem, j) => (
                                              <li
                                                key={`submenu-item-${j}-${submenuItemItem.name}`}
                                              >
                                                <Link
                                                  href={submenuItemItem.href}
                                                  as={submenuItemItem.as}
                                                >
                                                  <a
                                                    onClick={() => close()}
                                                    className="block py-2 px-4 text-primary-500 hover:bg-accent-500 hover:text-white"
                                                  >
                                                    <span className="text-base">
                                                      {submenuItemItem.name}
                                                    </span>
                                                  </a>
                                                </Link>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </li>
                                    ) : (
                                      <li>
                                        <Link
                                          key={submenuItem.name}
                                          href={submenuItem.href}
                                          as={submenuItem.as}
                                        >
                                          <a
                                            onClick={() => close()}
                                            className="block py-2 px-4 text-primary-500 hover:bg-accent-500 hover:text-white"
                                          >
                                            {submenuItem.name}
                                          </a>
                                        </Link>
                                      </li>
                                    )}
                                  </Fragment>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ) : (
                        <li className="border-b border-b-primary-100">
                          <Link href={menuItem.href} as={menuItem.as}>
                            <a
                              className="block py-2 px-4 text-primary-500 hover:text-accent-500"
                              onClick={() => close()}
                            >
                              {menuItem.name}
                            </a>
                          </Link>
                        </li>
                      )}
                    </Fragment>
                  ))}
                </ul>
              </nav>
            </div>
            <div className="fixed bottom-2 left-2 right-2 rounded-bl-lg rounded-br-lg bg-white md:left-1/2">
              <a
                className="flex cursor-pointer flex-row items-center justify-center border-b border-gray-50 bg-accent-100 py-3 px-4 text-primary-500 hover:text-primary-700"
                onClick={(e) => {
                  e.preventDefault()
                  setIsDialogOpen(true)
                }}
              >
                <SearchIcon className="mr-2 h-6 w-6" />
                <span>
                  <Trans>Search</Trans>
                </span>
              </a>
              <I18nSwitcher />
            </div>
          </div>
        )}
      </Popover.Panel>
    </Popover>
  )
}
export default SiteHeader
