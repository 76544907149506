const FormCheckbox = ({ label, value, onChange }) => {
  return (
    <label className="align-middle text-sm font-medium">
      <input
        type="checkbox"
        checked={value}
        onChange={onChange}
        className="form-check-input float-left mr-2 h-5 w-5 cursor-pointer rounded-sm border border-primary-300 bg-white bg-opacity-50 bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-primary-600 checked:bg-primary-600 focus:outline-none"
      />
      {label}
    </label>
  )
}
export default FormCheckbox
