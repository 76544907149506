const aboutItems = [
  {
    name: 'About',
    href: '/[[...slug]]',
    as: '/about',
  },
  {
    name: 'Team',
    href: '/team',
    as: '/team',
  },
  {
    name: 'Donors',
    href: '/donors',
    as: '/donors',
  },
  {
    name: 'Contact',
    href: '/[[...slug]]',
    as: '/contact',
  },
]

const ourWorkItems = [
  {
    name: 'UN level engagement',
    href: '/[[...slug]]',
    as: '/our-work/un-level-engagement',
  },
  {
    name: 'Regional & national advocacy',
    href: '/[[...slug]]',
    as: '/our-work/regional-national-advocacy',
  },
  {
    name: 'Learning Centre',
    href: '/[[...slug]]',
    as: '/our-work/learning-centre',
  },
  {
    name: 'Strategic plan',
    href: '/[[...slug]]',
    as: '/our-work/strategic-plan',
  },
]

const themesItems = [
  {
    name: 'Human rights',
    href: '/[[...slug]]',
    as: '/themes/human-rights',
  },
  {
    name: 'Decriminalisation',
    href: '/[[...slug]]',
    as: '/themes/decriminalisation',
  },
  {
    name: 'Gender',
    href: '/[[...slug]]',
    as: '/themes/gender',
  },
  {
    name: 'Health',
    href: '/[[...slug]]',
    as: '/themes/health',
  },
  {
    name: 'Development',
    href: '/[[...slug]]',
    as: '/themes/development',
  },
]

const newsroomItems = [
  {
    name: 'News',
    href: '/news',
    as: '/news',
  },
  {
    name: 'Blog',
    href: '/blog',
    as: '/blog',
  },
  {
    name: 'IDPC in the Media',
    href: '/media',
    as: '/media',
  },
  {
    name: 'Press Releases',
    href: '/press',
    as: '/press',
  },
  {
    name: 'Newsletter',
    href: '/[[...slug]]',
    as: '/newsroom/newsletter',
  },
  {
    name: 'Jobs Board',
    href: '/jobs',
    as: '/jobs',
  },
]

const items = [
  {
    name: 'Home',
    href: '/[[...slug]]',
    as: '/',
  },
  {
    name: 'About',
    submenu: aboutItems,
  },
  {
    name: 'Members',
    href: '/members',
    as: '/members',
  },
  {
    name: 'Our Work',
    submenu: [
      {
        items: ourWorkItems,
      },
      {
        name: 'Themes',
        items: themesItems,
      },
    ],
  },
  {
    name: 'Publications',
    href: '/publications',
    as: '/publications',
  },
  {
    name: 'Events',
    href: '/events',
    as: '/events',
  },
  {
    name: 'Newsroom',
    submenu: newsroomItems,
  },
]

export default items
