import { useEffect } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import NProgress from 'nprogress'
import SiteHeader from 'components/SiteHeader'
import SiteFooter from 'components/SiteFooter'
import PostFilter from 'components/PostFilter'
const SiteAnnouncement = dynamic(() => import('components/SiteAnnouncement'), {
  ssr: false,
})

NProgress.configure({ showSpinner: false })

const Layout = ({ children }) => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChangeStart = () => {
      NProgress.start()
    }
    const handleRouteChangeEnd = () => {
      NProgress.done()
    }
    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeEnd)
    router.events.on('routeChangeError', handleRouteChangeEnd)
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeEnd)
      router.events.off('routeChangeError', handleRouteChangeEnd)
    }
  }, [router])

  return (
    <div>
      <Head>
        <title key="title">International Drug Policy Consortium</title>
        <meta
          name="description"
          content="The International Drug Policy Consortium (IDPC) is a global network of over 192 NGOs that promote objective and open debate on drug policy at national, regional and international level."
          key="description"
        />
        <meta
          property="og:description"
          content="The International Drug Policy Consortium (IDPC) is a global network of over 192 NGOs that promote objective and open debate on drug policy at national, regional and international level."
          key="og:description"
        />
        <meta property="og:site_name" content="IDPC" key="og:site_name" />
        <meta property="og:type" content="website" key="og:type" />
        <meta property="og:locale" content={router.locale} key="og:locale" />
        <meta
          property="og:image"
          content="https://cdn.sanity.io/images/6u5teakk/production/f22b23a5d5f3627ed46d23efd418ee7a89b40f2a-2560x1687.jpg?w=1280"
          key="og:image"
        />
      </Head>
      <SiteAnnouncement />
      <SiteHeader />
      <main>{children}</main>
      <SiteFooter />
      <PostFilter />
    </div>
  )
}
export default Layout
