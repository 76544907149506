const items = [
  {
    name: 'IDPC',
    submenu: [
      {
        name: 'Sobre',
        href: '/[[...slug]]',
        as: '/nosotros',
        locale: 'es',
      },
      {
        name: 'Governance',
        href: '/team',
        as: '/team',
        locale: 'es',
      },
      {
        name: 'Vacantes de trabajo',
        href: '/jobs',
        as: '/jobs',
        locale: 'es',
      },
      {
        name: 'Política de privacidad',
        href: '/[[...slug]]',
        as: '/politica-de-privacidad',
      },
      {
        name: 'Contacto',
        href: '/[[...slug]]',
        as: '/contacto',
        locale: 'es',
      },
    ],
  },
  {
    name: 'Red internacional',
    submenu: [
      {
        name: 'Membresía',
        href: '/members',
        as: '/members',
        locale: 'es',
      },
      {
        name: 'Publicaciones',
        href: '/publications',
        as: '/publications',
        locale: 'es',
      },
      {
        name: 'Eventos',
        href: '/events',
        as: '/events',
        locale: 'es',
      },
    ],
  },
  {
    name: 'Nuestro trabajo',
    submenu: [
      {
        name: 'Incidencia a nivel del sistema ONU',
        href: '/[[...slug]]',
        as: '/nuestro-trabajo/incidencia-a-nivel-del-sistema-de-la-onu',
        locale: 'es',
      },
      {
        name: 'Incidencia a nivel nacional y regional',
        href: '/[[...slug]]',
        as: '/nuestro-trabajo/incidencia-a-nivel-nacional-y-regional',
        locale: 'es',
      },
      {
        name: 'Espacio de aprendizaje',
        href: '/[[...slug]]',
        as: '/nuestro-trabajo/espacio-de-aprendizaje',
        locale: 'es',
      },
      {
        name: 'Plan estratégico',
        href: '/[[...slug]]',
        as: '/nuestro-trabajo/plan-estratégico',
        locale: 'es',
      },
    ],
  },
  {
    name: 'Temas',
    submenu: [
      {
        name: 'Derechos humanos',
        href: '/[[...slug]]',
        as: '/temas/derechos-humanos',
        locale: 'es',
      },
      {
        name: 'Descriminalización',
        href: '/[[...slug]]',
        as: '/temas/descriminalización',
        locale: 'es',
      },
      {
        name: 'Género',
        href: '/[[...slug]]',
        as: '/temas/genero',
        locale: 'es',
      },
      {
        name: 'Salud',
        href: '/[[...slug]]',
        as: '/temas/salud',
        locale: 'es',
      },
      {
        name: 'Desarollo',
        href: '/[[...slug]]',
        as: '/temas/desarrollo',
        locale: 'es',
      },
    ],
  },
  {
    name: 'Actualidad',
    submenu: [
      {
        name: 'Noticias',
        href: '/news',
        as: '/news',
        locale: 'es',
      },
      {
        name: 'Blog',
        href: '/blog',
        as: '/blog',
        locale: 'es',
      },
      {
        name: 'IDPC en los medios',
        href: '/media',
        as: '/media',
        locale: 'es',
      },
      {
        name: 'Notas de prensa',
        href: '/press',
        as: '/press',
        locale: 'es',
      },
      {
        name: 'Boletines informativos',
        href: '/[[...slug]]',
        as: '/actualidad/boletines-informativos',
        locale: 'es',
      },
      {
        name: 'Vacantes de trabajo',
        href: '/jobs',
        as: '/jobs',
        locale: 'es',
      },
    ],
  },
]
export default items
